










































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import filterGroup from '@/components/filterGroup'
import debounce from 'lodash/debounce'
import { i18n } from '@/i18n/i18n'
import {
  ClassMaterialController,
  DropDownController,
  TaskController,
} from '@/services/request.service'
import { createMergeArray, getGuideViewStatus, getIntroShow, toPage } from '@/utils/utils'
import flexTooltip from '@/components/FlexTooltip.vue'
import DetailModal from '@/components/DetailModal.vue'
import moment from 'moment'
import { createPagination } from '@/constant/constant'
import Guide from '@/components/Guide.vue'
import TextFold from '@/components/TextFold.vue'

@Component({
  components: {
    filterGroup,
    flexTooltip,
    DetailModal,
    Guide,
    TextFold,
  },
})
export default class Teaching extends Vue {
  private courseLoading: any = false
  // type: 1001任务 1002资料
  private data: any = []
  private filters: any = {
    schoolYearId: undefined,
    type: 0,
    name: '',
    status: 0,
  }
  private loading: any = false
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private schoolYearList: any = []
  private courses: any = []
  private guideVis: any = false
  private selectedCourseId: any
  private detailModalShow: any = false
  private materialInfo: any = {
    title: '',
    infoList: [],
  }
  private courseType: any = ''
  private selectedKeys: any = []
  private expandedKeys: any = []
  private reqId: any = 0

  private get columns(): any {
    return [
      {
        dataIndex: 'type',
        width: '12%',
        ellipsis: true,
        title: this.$t('common.type'),
        scopedSlots: { customRender: 'type' },
      },
      {
        key: 'name',
        title: this.$t('common.name'),
        width: '44%',
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        dataIndex: 'endDate',
        key: 'deadline',
        ellipsis: true,
        width: '12%',
        title: this.$t('common.deadline'),
        scopedSlots: { customRender: 'deadline' },
      },
      {
        key: 'score',
        ellipsis: true,
        width: '8%',
        title: this.$t('common.score'),
        scopedSlots: { customRender: 'score' },
      },
      {
        dataIndex: 'creatorName',
        key: 'creator',
        width: '12%',
        ellipsis: true,
        title: this.$t('assignment.creator'),
        scopedSlots: { customRender: 'creator' },
      },
      {
        dataIndex: 'startDate',
        key: 'startDate',
        width: '12%',
        title: this.$t('teaching.startDate'),
        scopedSlots: { customRender: 'startDate' },
      },
    ]
  }

  private get ratio(): any {
    return this.$store.state.ratio
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'teaching'
  }
  private get locale(): any {
    return this.$store.state.locale
  }
  private get typeList(): any {
    return [
      {
        key: 0,
        label: this.$t('common.all'),
      },
      {
        key: 1001,
        label: this.$t('teaching.assignment'),
      },
      {
        key: 1002,
        label: this.$t('teaching.resource'),
      },
    ]
  }
  private get statusList(): any {
    return [
      {
        key: 0,
        label: this.$t('common.all'),
      },
      {
        key: true,
        label: this.$t('homework.complete'),
      },
      {
        key: false,
        label: this.$t('assignment.incomplete'),
      },
    ]
  }
  private get userId(): any {
    return this.$store.state.memberId
  }
  private get mergeCourse(): any {
    let courses: any = []
    this.courses.forEach(item => {
      createMergeArray(item, courses, '/')
    })
    return courses.map(item => ({
      ...item,
      type: item.type === '1001' ? 'subjectClass' : 'CCAClass',
    }))
  }
  private get allCourses(): any {
    return this.courses
  }

  private get currentUnHandNum(): number {
    return this.allCourses.find(item => this.selectedKeys.includes(item.key))?.unHandInNum
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    this.getCoursesList(true)
    // this.refresh()
  }

  private created(): void {
    this.getSchoolYearList()
  }

  private mounted() {
    let status = getGuideViewStatus()
    const introShow = getIntroShow() || {}
    if (!status.teaching && introShow.pc) {
      this.guideVis = true
    }
  }

  private changeYear(): void {
    this.$nextTick(() => {
      this.getCoursesList()
    })
  }

  private fetchData = debounce(() => {
    this.getCoursesList()
  }, 500)

  private getCoursesList(refresh = false): void {
    this.courseLoading = true
    this.courses = []
    this.data = []
    if (!refresh) {
      this.selectedKeys = []
      this.expandedKeys = []
      this.selectedCourseId = undefined
    }
    const reqId = ++this.reqId
    let condition = {
      schoolYearId: this.filters.schoolYearId,
      deleteFlag: true,
      courseFlag: true,
      name: this.filters.name.trim() || undefined,
      type: this.filters.type || undefined,
      status: this.filters.status === 0 ? undefined : this.filters.status,
    }
    DropDownController.getRelatedAllCourses(condition)
      .then(res => {
        if (reqId !== this.reqId) return
        this.courses = res.data.map(item => ({
          ...item,
          key: item.courseId,
          value: item.description,
        }))
        if (this.courses.length && !refresh) {
          this.selectedCourseId = this.courses[0].key
          this.selectedKeys = [this.selectedCourseId]
          this.expandedKeys = [this.courses[0].key]
          this.getData()
        } else if (this.courses.length) {
          this.refresh()
        }
        this.courseType = this.mergeCourse.find(item => item.key === this.selectedCourseId)?.type
        // this.courses = res[1].data.map(item => ({
        //   ...item,
        //   type: 'lifeBlock'
        // })).concat()
      })
      .catch(err => console.log(err))
      .finally(() => (this.courseLoading = false))
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.loading = true
    const condition = {
      pageSize: page.pageSize,
      pageCurrent: page.current,
      courseId: this.selectedCourseId,
      type: this.filters.type || undefined,
      schoolYearId: this.filters.schoolYearId,
      name: this.filters.name.trim(),
      status: this.filters.status === 0 ? undefined : this.filters.status,
    }
    TaskController.getMergeList(condition)
      .then(res => {
        this.data = res.data.items
        if (!res.data.totalItem) return
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearList().then(res => {
      this.schoolYearList = res.data
      this.filters.schoolYearId = (res.data[0] || {}).key
      if (this.filters.schoolYearId) {
        this.getCoursesList()
      }
    })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private onExpand(expandedKeys, { expanded: bool, node }): any {
    this.expandedKeys = expandedKeys
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private selectCourse(menuItem): void {
    // this.selectedKeys = key
    // if(!params.node.isLeaf || !params.selected)return
    // this.selectedCourseId = key[0].slice(2)
    // this.courseType = this.mergeCourse.find(item => item.key === Number(key[0].slice(2)))?.type
    // this.getData()
    this.selectedKeys = [menuItem.key]
    // if(!params.node.isLeaf || !params.selected)return
    this.selectedCourseId = menuItem.key
    this.courseType = this.mergeCourse.find(item => item.key === menuItem.key)?.type
    this.getData()
  }

  private viewDetail(record): void {
    if (record.type === '1001') {
      this.$router.push({
        name: 'assignmentDetail',
        params: { id: record.entityId },
        query: { assignType: this.courseType },
      })
    } else {
      ClassMaterialController.detail(record.entityId)
        .then(res => {
          this.materialInfo.title = this.$t('courseMaterial.materialDetail')
          this.materialInfo.infoList = this.dealDetailData(res.data)
          this.detailModalShow = true
          this.getCoursesList(true)
        })
        .catch(err => console.log(err))
    }
  }

  private dealDetailData(data): any {
    let nameItem = {
        key: 'name',
        label: this.$t('courseMaterial.name'),
        value: '',
      },
      startDateItem = {
        key: 'startDate',
        label: this.$t('teaching.startDate'),
        value: '',
      },
      courseItem = {
        key: 'course',
        label: this.$t('common.subjectClass'),
        value: '',
      },
      attachmentItem = {
        key: 'attachment',
        label: this.$t('courseMaterial.attachment'),
        value: '',
        attachments: [],
      },
      instructionItem = {
        key: 'instruction',
        label: this.$t('courseMaterial.instruction'),
        value: '',
        visRow: 6,
      }
    if (data) {
      nameItem.value = data.name
      startDateItem.value = moment(data.startDate).format('YYYY/MM/DD')
      const course = this.mergeCourse.find(item => item.key === Number(this.selectedCourseId))
      courseItem.value = course?.lastValue || course?.value
      instructionItem.value = data.remark
      attachmentItem.attachments = data.resources.map(item => {
        return {
          uid: item.resourceId,
          url: item.resourceUrl,
          name: item.resourceName,
          status: 'done',
        }
      })
    }
    return [nameItem, courseItem, startDateItem, attachmentItem, instructionItem]
  }

  private readAll(): void {
    TaskController.allRead()
      .then(res => {
        this.$message.success(this.$tc('common.readSuccess'))
        // this.refresh()
        this.getCoursesList(true)
      })
      .catch(err => {
        console.error(err)
      })
  }
}
