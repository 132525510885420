




















































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { TaskController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import Upload from '@/components/Upload.vue'
import { SimditorVue } from '@/components/simditor/Simditor'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    FlexTooltip,
    Upload,
    SimditorVue,
  },
})
export default class AssignmentDetail extends Vue {
  private assignmentForm: any = {
    attachments: [],
  }
  private assignmentInfo: any = {}
  private assignmentId: any
  private canLeave: boolean = false
  private detailModalShow: boolean = false
  private formLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private loading: boolean = true
  private moment = moment
  private resources: any = []
  private saveDraftLoading: boolean = false
  private studentData: any = []
  private submitLoading: boolean = false
  private uploading = false
  private descFold = false
  private descFoldShow = false
  private isEdit = false

  private get showEdit(): boolean {
    const { status, passed, overDeadline, online } = this.assignmentInfo
    return (
      status &&
      !this.loading &&
      !(passed && !overDeadline) &&
      online &&
      !this.assignmentInfo.score &&
      !this.isEdit
    )
  }

  private get showForm(): boolean {
    const { status, passed, overDeadline, online } = this.assignmentInfo
    if (status) {
      if (this.isEdit) {
        return !this.loading && !(passed && !overDeadline) && online
      } else {
        return false
      }
    } else {
      return !this.loading && !(passed && !overDeadline) && online
    }
  }
  private get infoList(): any {
    return [
      this.type === 'subjectClass'
        ? {
            label: this.$t('common.subject'),
            value: this.assignmentInfo.subjectName,
          }
        : -1,
      {
        label: this.$tc(`common.${this.type}`),
        value: this.assignmentInfo.courseName,
      },
      {
        label: this.$t('common.type'),
        value: this.assignmentInfo.typeName,
      },
      {
        label: this.$t('common.deadline'),
        value: this.assignmentInfo.endDate
          ? moment(this.assignmentInfo.endDate).format('YYYY.MM.DD HH:mm')
          : '',
      },
      {
        label: this.$t('assignment.scoreAllow'),
        value: this.$tc(`assignment.${this.assignmentInfo.scoreFlag ? 'allow' : 'disallow'}`),
      },
      this.assignmentInfo.scoreFlag
        ? [
            {
              label: this.$t('assignment.topScore'),
              value: this.assignmentInfo.topScore || 0,
            },
            {
              label: this.$t('assignment.totalIncluded'),
              value: this.$tc(`common.${this.assignmentInfo.inTotal ? true : false}`),
            },
          ]
        : -1,
      {
        label: this.$t('assignment.online'),
        value: this.$tc(`common.${this.assignmentInfo.online ? true : false}`),
      },
      this.assignmentInfo.online
        ? {
            label: this.$t('assignment.handInAfterDead'),
            value: this.$tc(
              `assignment.${this.assignmentInfo.overDeadline ? 'allow' : 'disallow'}`
            ),
          }
        : -1,
    ]
      .filter(item => item !== -1)
      .flat(1)
  }
  private get type(): any {
    return this.$route.query.assignType
  }

  private getAssignmentInfo(): void {
    this.loading = true
    TaskController.detail(this.assignmentId)
      .then(res => {
        this.assignmentInfo = res.data || {}
        this.calcExpand()
        this.$nextTick(() => {
          ;(this.$refs.aSim as any)?.changeValue(this.assignmentInfo.content)
          this.assignmentForm = {
            content: this.assignmentInfo.content,
            attachments: res.data.studentResources.map(item => ({
              uid: item.resourceId,
              url: item.resourceUrl,
              name: item.resourceName,
              status: 'done',
            })),
          }
        })
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private saveDraft(): void {
    this.saveDraftLoading = true
    const content = this.assignmentForm.content
    const resourceIds = this.assignmentForm.attachments.map(item => item.uid)
    const request = {
      taskStudentId: this.assignmentId,
      content: content,
      resourceIds: resourceIds,
    }
    TaskController.save(request)
      .then(res => {
        this.canLeave = true
        this.$message.success(this.$tc('homework.saveDraftSuccess'))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.saveDraftLoading = false
      })
  }

  private submit(): void {
    const form = this.$refs['assignmentForm'] as any
    form.validate(valid => {
      if (!valid) return false
      const request = {
        taskStudentId: this.assignmentId,
        content: this.assignmentForm.content,
        resourceIds: this.assignmentForm.attachments.map(item => item.uid),
      }
      this.submitLoading = true
      TaskController.handIn(request)
        .then(res => {
          this.canLeave = true
          this.$message.success(this.$tc('common.saveSuccess'))
          this.$route.meta.refresh = true
          this.getAssignmentInfo()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.submitLoading = false
          this.isEdit = false
        })
    })
  }

  private validateAnswer(rule, value, callback): void {
    const content = this.assignmentForm.content
    const attachments = this.assignmentForm.attachments
    if (attachments.length === 0 && (!content || !content?.trim())) {
      callback(this.$t('homework.inputNotBlank'))
    } else {
      callback()
    }
  }

  private calcExpand(): void {
    this.$nextTick(() => {
      if (this.descFoldShow) return
      this.descFoldShow = false
      let el = document.getElementById(`aDescContent`)
      if (!el) return
      let ch = el.clientHeight
      let sh = el.scrollHeight
      this.descFoldShow = sh > ch
    })
  }

  private onAttachChange(list): void {
    this.$nextTick(() => {
      const form = this.$refs['assignmentForm'] as any
      form.validateField('content', err => {
        return false
      })
    })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'assignmentDetail') {
      this.assignmentId = to.params.id
      this.getAssignmentInfo()
    }
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave && this.assignmentInfo.online && !this.assignmentInfo.status) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        this.canLeave = false
        next()
      }
    } else {
      next()
    }
  }

  private cancelEdit(): void {
    this.isEdit = false
    this.getAssignmentInfo()
  }
}
